/* .cart-item-container {
    display: flex;
} */

.cart-item-container {
    display: flex;
    justify-content: space-between;
    margin: 45px 0px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.08);
}

.cart-item-left-container {
    display: flex;
    gap: 10px;
    margin: 0 10px;
}

.cart-item-middle-container {
    margin-bottom: 15px;
}

/* .cart-item-right-container {
    text-align: end;
} */

.cart-item-name {
    width: 50vw;
    line-height: 1.3em;
    word-break: normal;
    color: #0F1111;
    font-size: 18px;
    cursor: pointer;
}

.by-seller {
    color: #565959;
    font-size: 12px;
    line-height: 16px;
    margin-top: 5px;
}

.cart-item-prime {
    background-image: url("https://d1irxr40exwge2.cloudfront.net/labels.png");
    width: 53px;
    height: 15px;
    background-position: -185px -182px;
}

.stock {
    color: #007600;
    font-size: 12px;
    line-height: 16px;
    margin: 7px 0;
}

.cart-item-name:hover {
    text-decoration: underline;
    color: #c45500;
}

.cart-item-image {
    height: 160px;
    width: 160px;
    margin-bottom: 30px;
    object-fit: scale-down;
}

.cart-item-delete {
    color: #007185;
    font-size: 12px;
}

.cart-item-delete:hover {
    cursor: pointer;
    text-decoration: underline;
}

.gift-opt {
    margin: 7px 0;
}

.qty-delete {
    display: flex;
    gap: 10px;
    align-items: center;
}

.line {
    text-rendering: optimizeLegibility;
    background-color: #dddddd;
    width: 1px;
    height: 15px;
}

.cart-item-price {
    font-weight: 800;
}

.item-deleted {
    color: #007185;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 14px;
    line-height: 20px;
    color: #0F1111;
}

.item-delete-link:hover {
    cursor: pointer;
    text-decoration: underline;
}
