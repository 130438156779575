.user-review-item-container {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px #D5D9D9 solid;
    padding: 0 20px 30px 20px;
    gap: 50px;
    margin-bottom: 15px;
}

.user-review-product-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    /* margin-top: 30px; */
}

.user-review-item-p-name {
    color: #007185;
    font-size: 14px;
    line-height: 19px;
}


.user-review-item-p-name:hover {
    color: #C7511F;
    text-decoration: underline;
}

.user-review-item-image {
    height: 110px;
    width: 110px;
    object-fit: scale-down;
    margin-bottom: 10px;
}

.user-review-page-item-container {
    width: 50%;
}
