.user-product-item-container {
    /* display: flex; */
    width: 100%;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
    padding: 10px 0;
    font-size: 14px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto;
    justify-items: center;
    align-items: center;
}

.product-item-name {
    font-size: 14px;
    width: 12.5vw;
    padding: 0px 5px;
}

.product-item-description {
    font-size: 14px;
    /* width: 12.5vw; */
    padding: 0 5px;
}

.product-item-category {
    /* width: 10vw; */
    text-align: center;
    margin-left: -20px;
}

.product-item-price {
    width: 5vw;
    text-align: center;
    margin-left: -18px;
}

.product-item-inventory {
    width: 4vw;
    text-align: center;
    margin-left: -27px;
}

.product-created {
    /* width: 9vw; */
    text-align: center;
    margin-left: -21px;
}

.edit-delete-item-container {
    display: flex;
    /* justify-content: center; */
    margin-left: -10px;
    text-align: center;
}

.user-product-image {
    height: 60px;
    width: 60px;
    object-fit: scale-down;
}

.image-link {
    color: black
}

.image-link:hover {
    text-decoration: underline;
}

.edit-delete-item-container {
    display: flex;
    gap: 10px;
}

.edit-product-container {
    border: 1px solid #a88734;
    border-radius: 3px;
    padding :3px 12px;
    font-size: 14px;
    text-align: center;
    background: linear-gradient(0deg, rgba(240, 194, 77, 1) 0%, rgba(246, 221, 160, 1) 100%);
    color: #111111;
    cursor: pointer;
}

/* .edit-product-container:hover {

} */

.delete-product {
    border: 1px solid #a88734;
    border-radius: 3px;
    padding: 3px 12px;
    font-size: 14px;
    text-align: center;
    background: linear-gradient(0deg, rgba(240, 194, 77, 1) 0%, rgba(246, 221, 160, 1) 100%);
    color: #111111;
    cursor: pointer;
}
