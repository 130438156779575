.my-links {
    text-decoration: none;
    color: white;
    font-size: 14px;
    line-height: 12px;
}

.slider-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.slider-container {
    position: relative;
    width: 100%;
    /* max-width: 1500px; */
    height: 800px
}

.slide-button {
    background-color: transparent;
    border: 1px solid transparent;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chev-left {
    height: 40px;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    padding: 104px 28px;
    border: 1px solid transparent;
    text-shadow: white 2px 0px;
}

.chev-right {
    height: 40px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 104px 28px;
    border: 1px solid transparent;
    text-shadow: white -2px 0px;
}


.chev-left:active {
    border: 2px solid #232F3E;
    border-radius: 3px;
}

.chev-right:active {
    border: 2px solid #232F3E;
    border-radius: 3px;
}

.product-cards-container {
    width: 98%;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: row;
    gap: 1vw;
    top: 42%;
    /* padding-left: 22px;
    padding-right: 22px; */
    margin-left: 40px;
    margin-right: 40px;
    max-width: 1420px;
    max-height: 68%;
    justify-content: space-evenly;
    overflow-y: visible;
    z-index: 2;
    max-width: 1500px;
}

.card-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    /* padding: 0px 5px 0 5px; */
    /* max-width: 350px; */
    width: 23vw;
    /* padding: 2vw; */
    /* overflow-y: auto; */
    height: 420px;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 55%);
}

.card-container-explore {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 23vw;
    height: 420px;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 55%);
}

.card-container-trending {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 23vw;
    height: 420px;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 55%);
}

@media screen and (max-width: 1350px) {
    .card-container {
       width: 30vw;
    }

    .card-container-trending {
        width: 30vw;
    }
    .card-container-explore {
        display: none;
    }
}

@media screen and (max-width: 1050px) {
    .card-container {
       width: 35vw;
    }

    .card-container-explore {
        display: none;
    }

    .card-container-trending {
        display: none;
    }
}


.card-image-info {
    font-size: 11px;
    margin-top: 5px;
    text-align: center;
}

.card-image-link {
    height: 90px;
    width: 100%;
    max-height: 80%;
    margin-bottom: -12px;
}

.card-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: start;
    margin: 0;
    padding: 0 7px;
}

.card-title {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    margin: 0;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}

.card-image-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    /* gap: 5px; */
    width: 100%;
    height: 80%;
    margin-top: 0px;
    /* box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%); */
    /* border: 1px solid blue */
}

.card-actual-image {
    height: 110px;
    width: 100%;
    max-height: 80%;
    object-fit: contain;

}
