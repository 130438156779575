.sign-in-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
}

.logo-sign-in {
    padding-bottom: 20px;
    padding-top: 5px;
    font-weight: 1000;
    font-size: 20px;
}

.sign-in-header {
    font-size: 28px;
}

.sign-in-form {
    display: flex;
    flex-direction: column;
    width: 350px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px 25px;
    margin-top: -10px;
}

.sign-in-label {
    font-size: 13px;
    font-weight: 700;
}

.sign-in-input {
    height: 31px;
    width: 100%;
    margin-top: 2px;
    border: 1px solid #a6a6a6;
    border-top-color: #949494;
    border-radius: 3px;
    text-indent: 7px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.sign-in-input:focus {
    outline: none;
    border: 1px solid #e77601;
    filter: drop-shadow(0px 0px 2px #e88420);
}

.continue-button {
    width: 100%;
    padding: 5px 6px;
    margin-bottom: 20px;
    border: 1px solid #a88734;
    border-radius: 3px;
    background: linear-gradient(0deg, rgba(240, 194, 77, 1) 0%, rgba(246, 221, 160, 1) 100%);
    color: #111111;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
}

.continue-button:hover {
    background: linear-gradient(0deg, rgba(238, 186, 53, 1) 0%, rgba(244, 213, 137, 1) 100%);
}

.sign-in-terms {
    font-size: 12px;
    color: #111111;
    margin-bottom: 15px;
}

.sign-in-demo {
    cursor: pointer;
    font-size: 13px;
    color: #0066c0;
}

.sign-demo:hover {
    color: #c45500;
    text-decoration: underline;
}

.error-icon-container {
    display: flex;
}

.sign-in-error {
    display: flex;
    gap: 79px;
}

.sign-in-errors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    list-style: none;
    color: red;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 5px 0;
}

.li-error {
    width: 100%;
    font-weight: 700;
}

.error-icon {
    height: 20px;
    width: 20px;
}

.sign-in-vector {
    height: 80px;
    width: 80px;
    margin-top: -60px;
}

.new-to-rainforest {
    margin-top: 20px;
    width: 400px;
    box-sizing: border-box;
}

.new-header-line {
    text-align: center;
    position: relative;
    top: 2px;
    padding-top: 1px;
    margin-bottom: 14px;
    line-height: 0;
}

.new-header-line::after {
    content: "";
    width: 100%;
    display: block;
    border-top: 1px solid #e7e7e7;
    position: absolute;
    top: 50%;
    margin-top: -1px;
}

.new-header {
    line-height: 1;
    font-size: 12px;
    color: #767676;
    z-index: 2;
    position: relative;
    display: inline-block;
    background-color: white;
    padding: 0 8px 0 7px;
}

.new-create-account {
    text-align: center;
    color: #111111;
    font-size: 13px;
    line-height: 29px;
    background: linear-gradient(0deg, #e7e9ec 0%, #f6f7f9 100%);
    border: 1px solid #adb1b8;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 15px;
}
