.order-container {
    border-radius: 8px;
    border: 1px #D5D9D9 solid;
    margin-bottom: 12px;
}

.order-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 33px;
    padding: 15px 18px;
    background-color: #F0F2F2;
    border-bottom: 1px #D5D9D9 solid;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #565959;
}

.order-left-header {
    display: flex;
    gap: 50px;
}

.order-right-header {
    font-size: 12px;
    line-height: 16px;
}

.order-placed, .order-total-text, .order-ship-to {
    font-size: 12px;
}

.order-placed-date, .order-total-price, .order-user {
    font-size: 14px;
    line-height: 20px;
}

.remove-order-container {
    border-top: 1px #D5D9D9 solid;
    font-size: 14px;
    color: #007185;
    padding: 7px 18px 7px 18px;
    cursor: pointer;
}
