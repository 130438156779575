.all-review-images-image {
    width: 130px;
    height: 130px;
    object-fit: fill;
}

.all-review-images-images {
    display: flex;
    display: grid;
    margin: 15px 25px;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    /* gap: 10px; */
}

