.stars-form {
    background-image: url("https://d1irxr40exwge2.cloudfront.net/stars.png");
    background-repeat: no-repeat;
    align-self: center;
    height: 22px;
    width: 95px;
}

.form-five {
    background-position: -99px -366px
}

.form-four_half {
    background-position: -390px -462px
}

.form-four {
    background-position: -2px -342px
}

.form-three_half {
    background-position: -196px -414px
}

.form-three {
    background-position: -293px -438px
}

.form-two_half {
    background-position: -2px -366px
}

.form-two {
    background-position: -99px -390px
}

.form-one_half {
    background-position: -293px -462px
}

.form-one {
    background-position: -390px -486px
}

.form-zero_half {
    background-position: -99px -414px
}

.form-zero {
    background-position: -196px -438px
}
