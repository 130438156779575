.sign-up-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
}

.sign-up-form {
    display: flex;
    flex-direction: column;
    width: 350px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px 20px;
    margin-top: -10px;
}

.sign-up-label {
    font-size: 13px;
    font-weight: 700;
}

.sign-up-input {
    height: 31px;
    width: 100%;
    margin-top: 2px;
    border: 1px solid #a6a6a6;
    border-top-color: #949494;
    border-radius: 3px;
    text-indent: 7px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.sign-up-input:focus {
    outline: none;
    border: 1px solid #e77601;
    filter: drop-shadow(0px 0px 2px #e88420);
}

.already-have-sign-in {
    display: flex;
    gap: 5px;
    font-size: 13px;
    line-height: 19px;
    color: #111;
}

.sign-in-line {
    width: 100%;
    height: 14px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .14), rgba(0, 0, 0, .03) 3px, transparent);
}
.error-icon-container {
    display: flex;
    justify-content: center;
}

.sign-up-errors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    list-style: none;
    color: red;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 5px 0;
}

.li-error {
    width: 100%;
    font-weight: 700;
}

.error-icon {
    height: 20px;
    width: 20px;
    margin-bottom: 10px;
}
