.checkout-header-container {
    display: flex;
    width: 100%;
    height: 60px;
    background-color: rgb(0, 0, 0, 0.06);
}

.checkout-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding: 0 40px;
}

.checkout-header-items {
    display: flex;
    gap: 5px;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #0F1111;
    margin-top: -10px;
}

.checkout-numitems {
    color: #007185;
}

.checkout-link {
    color: black;
    font-size: 18px;
    font-weight: 900;
}

.checkout-link-name {
    color: black;
    font-size: 18px;
    font-weight: 500;
}

.checkout-link-name:hover {
    text-decoration: underline;
    color: #c45500;
}

.checkout-logo {
    display: flex;
    flex-direction: column;
    margin-top: -6px;
}

.vector-checkout {
    height: 80px;
    width: 80px;
    object-fit: scale-down;
    margin-top: -40px;
    margin-left: 31px;
}
