.product-info-container {
    width: 32vw;
    display: flex;
    flex-direction: column;
    margin-right: 25px;
    height: fit-content;
}

.product-name {
    font-size: 24px;
    color: #0F1111;
    word-break: break-word;
    line-height: 32px;
    text-rendering: optimizeLegibility;
    width: 100%
}

.product-info-prime {
    background-image: url("https://d1irxr40exwge2.cloudfront.net/labels.png");
    width: 53px;
    height: 15px;
    background-position: -185px -182px;
    margin: 10px 0;
}

.product-sold-by {
    font-size: 14px;
    line-height: 20px;
    color: #007185;
    border-bottom: 1px solid rgb(0, 0, 0, 0.08);
    padding-bottom: 5px;
    cursor: pointer;
}

.product-price {
    display: flex;
    gap: 8px;
    color: #0F1111;
    font-size: 18px;
    padding-top: 5px;
}

.dollars {
    color: #B12704;
}

.price-count {
    color: #0F1111;
    font-size: 12px;
    line-height: 16px;
    margin-top: 3px;
}

.free-returns {
    color: rgb(0, 113, 133);
    font-size: 14px;
    line-height: 20px;
}

.promo {
    color: rgb(15, 17, 17);
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.08);
}

.get-a {
    font-weight: 500;
    color: rgb(0, 113, 133);
    font-size: 14px;
    line-height: 20px;
}

.about-item {
    display: flex;
    flex-direction: column;
}

.about-item-header {
    color: rgb(15, 17, 17);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 4px;
    margin-top: 0.67em;
}

.product-description {
    color: rgb(15, 17, 17);
    font-size: 14px;
    line-height: 20px;
    word-break: break-word;
}

.ul-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding-left: 13px;
}
