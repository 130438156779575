.profile-dropdown {
  position: absolute;
  z-index: 1;
  top: 44px;
  right: 187.2px;
  width: 113px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 10px;
  color: black;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 5;
}

.drop-link {
  text-decoration: none;
  color: black;
  font-size: 13px;
  font-weight: 400;
  padding: 5px 0;
}

.triangle-drop {
  position: absolute;
  top: 52px;
  right: 194.8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
}

.your-account {
  color: #0F1111;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 5px;
}

.drop-options {
  display: flex;
  flex-direction: column;
}

.drop-logout {
  color: black;
  font-size: 13px;
  font-weight: 400;
  padding: 5px 0;
  cursor: pointer;
}

.hidden {
  display: none;
}

.nav-bar-container {
  /* border: 1px solid red; */
  background-color: #131921;
  width: 100%;
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  color: white;
  padding: 0 18px;
}

.nav-bar-nav-link {
  text-decoration: none;
  color: white;
}

.rainforest-retail-nav {
  font-size: 18px;
  font-weight: 700;
}

.logo {
  margin-left: 10px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.lang-profile-orders-cart {
  display: flex;
}

.flag-lang {
  display: flex;
  align-items: center;
  margin-right: 20px;
  gap: 3px;
}

.flag {
  height: 24px;
  width: 20px;
}

.en {
  color: white;
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
}

.profile-button-container {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.profile-button-container:hover {
  border: 1px solid white;
  border-radius: 2px;
}

.profile-button {
  cursor: pointer;
}

.hello-user {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.account-lists {
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
}

.account-triangle {
  display: flex;
  align-items: center;
  gap: 5px;
}

.drop-signin, .drop-demo {
  width: 100px;
  padding: 5px 6px;
  border: 1px solid #a88734;
  border-radius: 3px;
  background: linear-gradient(0deg, rgba(240, 194, 77, 1) 0%, rgba(246, 221, 160, 1) 100%);
  color: #111111;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.drop-signin:hover {
  background: linear-gradient(0deg, rgba(238, 186, 53, 1) 0%, rgba(244, 213, 137, 1) 100%);
}

.drop-demo:hover {
  background: linear-gradient(0deg, rgba(238, 186, 53, 1) 0%, rgba(244, 213, 137, 1) 100%);
}

.drop-sign-up {
  display: flex;
  flex-direction: row;
}

.new-cust {
  color: black;
  font-size: 10px;
}

.start-here-link {
  /* color: black; */
  font-size: 10px;
  color: #0066c0;
}

.start-here:hover {
  text-decoration: underline;
}


.vector {
  margin-top: -40px;
  height: 80px;
  width: 80px;
  margin-left: 30px;
}

.header-left {
  margin-right: 80px;
}
/*
.header-right {
  margin-left: 10px;
} */
