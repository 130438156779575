.order-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 18px;
}

.order-item-left {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.order-item-header {
    display: flex;
    flex-direction: column;
}

.order-item-arriving {
    color: #0F1111;
    font-size: 18px;
    line-height: 24px;
    font-weight: 900;
    margin-bottom: 2px;
}

.not-shipped {
    color: #0F1111;
    font-size: 13px;
    /* line-height: 24px; */
    font-weight: 300;
    margin-bottom: 15px;
}

.order-item-product {
    display: flex;
    flex-direction: row;
}

.order-item-image {
    height: 90px;
    width: 90px;
    object-fit: scale-down;
    margin-right: 12px;
    margin-top: -5px;
}

.order-item-name-buttons {
    display: flex;
    flex-direction: column;
}

.order-item-name {
    color: #007185;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
}

.order-item-name:hover {
    color: #C7511F;
    text-decoration: underline;
}

.order-item-name-quantity {
    display: flex;
    align-items: flex-start;
}

.order-item-quantity {
    font-size: 15px;
    color: black;
    margin-left: 20px;
}


.buy-view-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.buy-it-again {
    display: flex;
    align-items: center;
    gap: 13px;
    width: 122px;
    padding-left: 2px;
    box-sizing: border-box;
    font-size: 13px;
    /* line-height: 27px; */
    color: #0F1111;
    text-align: center;
    background: #FFD814;
    border: 1px solid #FCD200;
    border-radius: 7px;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
    cursor: pointer;
}

.buy-it-again:hover {
    background: #F7CA00;
    border-color: #F2C200;
}

.buy-it-again-icon {
    background-image: url("https://d1irxr40exwge2.cloudfront.net/buyItAgain.png");
    background-size: 25px;
    height: 25px;
    width: 25px;
}

.buy-it-again-text {
    margin-bottom: 1.5px;
}

.view-item-link {
    padding: 0px 10px;
    font-size: 13px;
    line-height: 29px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
    border: 1px solid #D5D9D9;
    text-align: center;
    color: #0F1111;
    cursor: pointer;
}

.view-item-link:hover {
    background-color: #F7FAFA;
    border-color: #D5D9D9;
    color: #0F1111;
    text-decoration: none;
}

.order-write-a-review-link {
    padding: 7px 40px;
    font-size: 13px;
    line-height: 29px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
    border: 1px solid #D5D9D9;
    text-align: center;
    color: black;
    cursor: pointer;
    margin-top: 5px;
}

.order-write-a-review-link:hover {
    background-color: #F7FAFA;
    border-color: #D5D9D9;
    color: #0F1111;
    text-decoration: none;
}

@media screen and (max-width: 920px) {
    .order-write-a-review-link {
        padding: 7px 20px;
    }
}

@media screen and (max-width: 760px) {
    .order-write-a-review-link {
        padding: 7px 10px;
    }
}

@media screen and (max-width: 680px) {
    .order-write-a-review-link {
        padding: 7px 5px;
    }
}

@media screen and (max-width: 640px) {
    .order-write-a-review-link {
        display: none;
    }
}
