@media screen and (max-width: 1050px) {
    .product-show-container {
        margin: 0 40px;
        width: 80%;
    }
    .product-show-top {
        margin: 0 40px;
    }

    .product-show-bottom {
        margin: 0 40px;
    }
}

.product-show-container {
    padding: 20px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: 100%; */
    padding-bottom: 200px;
}

.product-show-top {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #D5D9D9;
    padding-bottom: 25px;
    width: 80vw;
    /* justify-content: center; */
    /* height: fit-content; */
}

.product-show-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 25px;
    width: 80vw;
    /* margin-left: 100px; */
}
