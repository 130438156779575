.cart-confirm-container {
    display: flex;
    padding: 20px;
    gap: 20px;
    background-color: rgb(0, 0, 0, 0.04);
    /* height: 100%; */
    padding-bottom: 1050px;
}

.cart-confirm-left {
    display: flex;
    flex-grow: 1;
    background-color: white;
    gap: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 160px;
    padding-left: 40px;
    padding-right: 40px;
}

.cart-confirm-added-name {
    display: flex;
    flex-direction: column;
}

.added-to-cart-check {
    display: flex;
    gap: 10px;
}

.added-to-cart {
    font-weight: 700;
    font-size: 20px;
}

.cart-confirm-right {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    width: 25vw;
    gap: 10px;
    height: 160px;
    box-sizing: border-box;
}

@media screen and (max-width: 950px) {
    .cart-confirm-right {
        height: 180px;
    }

    .cart-confirm-left {
        height: 180px;
    }
}

@media screen and (max-width: 850px) {
    .cart-confirm-right {
        height: 200px;
    }

    .cart-confirm-left {
        height: 200px;
    }
}

.confirm-right-header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
}

.cart-confirm-name {
    margin-top: 10px;
    font-size: 14px;
    color: #0F1111;
}

.checkmark {
    background-image: url("https://d1irxr40exwge2.cloudfront.net/stars.png");
    height: 18px;
    width: 18px;
    background-position: -84px -138px;
    align-self: center;
}

.cart-confirm-image {
    width: 100px;
    height: 100px;
    object-fit: scale-down;
}

.cart-confirm-link-proceed {
    background: #FFD814;
    border: 1px solid #FCD200;
    cursor: pointer;
    font-size: 13px;
    line-height: 29px;
    color: #0F1111;
    padding: 3px 10px;
    text-align: center;
    border-radius: 7px;
}

.cart-confirm-link-proceed:hover {
    background: #F7CA00;
    border-color: #F2C200;
}

.cart-confirm-link-go-to-cart {
    font-size: 13px;
    line-height: 29px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
    border: 1px solid #D5D9D9;
    text-align: center;
    color: #0F1111;
    padding: 3px 10px;
}

.cart-confirm-link-go-to-cart:hover {
    background-color: #F7FAFA;
    border-color: #D5D9D9;
    color: #0F1111;
    text-decoration: none;
}

.cart-confirm-link {
    text-decoration: none;
    color: black
}
