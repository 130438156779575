.order-page-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 150px;
}

.order-page-container {
    margin-top: 10px;
    margin-left: 20px;
    width: 920px;
}

.order-page-nav {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: #565959;
    font-size: 14px;
    line-height: 20px;
}

.order-arrow {
    margin: 0 4px;
}

.your-orders-nav {
    color: #c45500;
}

.orders-page-header {
    font-size: 25px;
    width: 100%;
    color: #0F1111;
    font-weight: 400;
    line-height: 36px;
    font-size: 28px;
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px #D5D9D9 solid;
}

/* .order-items-wrapper {

} */

.order-arrow {
    font-weight: 900;
    height: 7px;
    width: 5px;
    margin-top: 1px;
}
