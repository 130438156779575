.quantity-show-container {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 0 5px;
    width: 55px;
    height: 22px;
    min-width: 0.7%;
    margin-top: 12px;
    border: 1px solid #D5D9D9;
    border-radius: 8px;
    color: #0F1111;
    background: #F0F2F2;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 90%);
    line-height: 20px;
}

.qty-show-title {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    font-size: 11px;
}

.qty-select {
    border: none;
    background: #F0F2F2;
    cursor: pointer;
    font-size: 11px;
}

.qty-select:focus {
    background: #F0F2F2;
    outline: none;
}
