.user-product-page-container {
    margin: 0px 30px;
    color: black;
    height: 100%;
    padding-bottom: 100px;
}

.user-product-page-header {
    display: flex;
    /* width: 85vw; */
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

.user-products-page-header {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto;
    justify-items: center;
    align-items: center;
    background-color: #f7f7f7;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

.no-products-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    /* padding-bottom: 600px; */
}

.no-products-header {
    display: flex;
    justify-content: center;
    font-size: 19px;
    font-weight: 800;
    margin-bottom: 20px;
}

.instructions-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
}

.instructions {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.fa-turn-down {
    margin-left: 5px;
}

.create-product-big {
    width: 330px;
    padding: 10px 6px;
    border: 1px solid #a88734;
    border-radius: 3px;
    background: linear-gradient(0deg, rgba(240, 194, 77, 1) 0%, rgba(246, 221, 160, 1) 100%);
    color: black;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.create-product-big-link {
    display: flex;
    justify-content: center;
}

.manage-products {
    font-size: 20px;
    font-weight: 800;
}

.all-user-products {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}

.user-product-page-link {
    text-decoration: none;
    color: black;
}

.create-product {
    width: 115px;
    padding: 5px 6px;
    border: 1px solid #a88734;
    border-radius: 3px;
    background: linear-gradient(0deg, rgba(240, 194, 77, 1) 0%, rgba(246, 221, 160, 1) 100%);
    color: black;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}

/* .user-products-page-header {
    display: flex;
    font-weight: 700;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
    padding: 15px 0;
} */
