@media screen and (min-width: 1500px) {
    .home-page-container {
        margin: 0 auto;
        width: 1500px;
    }

    .product-card-container {
        margin: 0 auto;
    }
}

.home-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #E3E6E6;
    padding-bottom: 145px;
}

.home-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1500px;
    /* background-color: rgb(0, 0, 0, 0.1); */
    /* background-color: #E3E6E6; */
    background-image: linear-gradient(to top, rgba(227, 230, 230, 0.8) 0%, rgba(227, 230, 230, 0.1) 600px, rgba(227, 230, 230, 0) 100%);
    /* background: linear-gradient(to bottom, rgba(227,230,230,0.9) 0%, rgba(227,230,230,0.7) 20%, rgba(227,230,230,0.5) 50%, rgba(227,230,230,0.3) 80%, rgba(227,230,230,0) 100%); */
}

.book-carousel-container {
    margin-top: -60px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;
    /* padding-bottom: 20px; */
    background-color: white;
}

.book-carousel-title, .phone-computer-carousel-title {
    font-size: 20px;
}

.book-carousel-slide {
    display: flex;
    justify-content: space-around;
    padding-bottom: 30px;
}

.book-carousel-product-image {
    width: 150px;
    height: 150px;
    object-fit: scale-down;
}

.book-carousel-product {
    cursor: pointer;
}

.phone-computer-carousel-container {
    margin-top: 75px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;
    /* padding-bottom: 20px; */
    background-color: white;
}

.phone-computer-carousel-slide {
    display: flex;
    justify-content: space-around;
    padding-bottom: 30px;
}

.phone-computer-carousel-product-image {
    width: 130px;
    height: 130px;
    object-fit: scale-down;
}

.book-carousel-product {
    cursor: pointer;
}

button.control-arrow {
    opacity: 0.6 !important;
    border-radius: 3px !important;
}

p.carousel-status {
    /* border: 1px solid red; */
    margin-top: -7px;
}

li.dot {
    background-color: rgb(0, 0, 0, 0.2) !important;
    margin: 0 15px !important;
}
