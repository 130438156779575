.product-images-container {
    display: flex;
    height: fit-content;
}

.small-images-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.small-image {
    width: 42px;
    height: 42px;
    cursor: pointer;
    border: 1px solid #a2a6ac;
    border-radius: 1px;
    object-fit: scale-down;
}

.image-hover {
    border-color: #e77601;
    box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
}

.main-image-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
    margin: 0 25px;
}

.main-image {
    width: 320px;
    height: 320px;
    object-fit: contain;
}

/* .scaleDown {
    transform: scale(0.5);
} */
/*
@media screen and (max-width: 1879px) {
    .main-image-container {
        width: 44.5vw;
    }

    .main-image {
        width: 100%;
        padding-top: 0px;
        padding-right: 10px;
        padding-left: 3px;
    }
} */
