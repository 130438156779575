/* TODO Add site wide styles */

@font-face {
    font-family: "Amazon Ember";
    font-weight: 400;
    src: url('https://d1irxr40exwge2.cloudfront.net/AmazonEmber_Rg.ttf');
}

@font-face {
    font-family: "Amazon Ember";
    font-weight: 500;
    src: url('https://d1irxr40exwge2.cloudfront.net/Amazon-Ember-Medium.ttf');
}

@font-face {
    font-family: "Amazon Ember";
    font-weight: 700;
    src: url('https://d1irxr40exwge2.cloudfront.net/AmazonEmber_Bd.ttf');
}

#root {
    /* height: 100%; */
    padding: 0;
    margin: 0;
    width: 100%;
}

a {
    /* color: #0066c0; */
    text-decoration: none;
    cursor: pointer;
}


body {
    /* height: 100%; */
    margin: 0;
    padding: 0;
    overflow: auto;
    font-family: "Amazon Ember", Arial, sans-serif;
}

html {
    /* height: 100%; */
    margin: 0;
    padding: 0;
    overflow: auto;
    font-family: "Amazon Ember", Arial, sans-serif;
}
