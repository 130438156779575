.orders-button-container {
    width: 80px;
    padding: 0px 5px;
    /* padding-top: 8px;
    padding-bottom: 9px; */
    /* margin-top: 2px; */
    border: 1px solid transparent;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orders-button-container:hover {
    border: 1px solid white;
}

.returns-orders-container {
    margin: 9px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.returns {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
}

.orders {
    font-size: 14px;
    line-height: 15px;
    font-weight: 700;
}
