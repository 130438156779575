.cart-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 750px;
    background-color: #eaeded;
}

.empty-cart-container {
    padding-bottom: 500px;
}

.left-cart-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 77vw;
    height: fit-content;
    padding: 20px;
}

.right-cart-container {
    width: 23vw;
    display: flex;
    flex-direction: column;
    background-color: white;
    height: fit-content;
    padding: 20px;
}

.right-cart-header {
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: flex-start;
}

.right-cart-subtotal {
    display: flex;
    gap: 3px;
    font-size: 18px;
    line-height: 24px;
}

.cart-dollars {
    font-weight: 1000;
}

.cart-right-subtotal {
    font-weight: 500
}

.cart-footer-subtotal {
    font-weight: 500;
}

.cart-footer {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    gap: 3px;
}

.shopping-cart {
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    padding-bottom: 20px;
}

.cart-price {
    color: #565959;
    text-align: right;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.08);
}

.empty-cart-container {
    /* min-height: 100%; */
    background-color: #eaeded;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.empty-cart-header {
    margin: 20px 0;
    font-size: 20px;
    font-weight: 800;
}

.empty-cart-link {
    text-decoration: none;
    color: black;
    width: 310px;
    padding: 10px 6px;
    border: 1px solid #a88734;
    border-radius: 3px;
    background: linear-gradient(0deg, rgba(240, 194, 77, 1) 0%, rgba(246, 221, 160, 1) 100%);
    color: black;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.recommended-for-you {
    font-size: 20px;
    margin-top: 100px;
    font-weight: 800;
}

.empty-cart-cards-container {
    width: 98%;
    /* position: absolute; */
    z-index: 1;
    display: flex;
    flex-direction: row;
    gap: 1vw;
    margin-top: 20px;
    /* padding-left: 22px;
    padding-right: 22px; */
    margin-left: 40px;
    margin-right: 40px;
    max-width: 1420px;
    max-height: 68%;
    justify-content: space-evenly;
    overflow-y: visible;
    z-index: 2;
    max-width: 1500px;
}

.cart-show-link {
    background: #FFD814;
    border: 1px solid #FCD200;
    cursor: pointer;
    font-size: 13px;
    line-height: 29px;
    color: #0F1111;
    padding: 0 10px 0 11px;
    text-align: center;
    border-radius: 7px;
    margin-top: 20px;
}

.card-container-beauty, .card-container-appliances {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 23vw;
    height: 420px;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 55%);
}

@media screen and (max-width: 1350px) {
    .card-container {
       width: 30vw;
    }

    .card-container-beauty {
        width: 30vw;
    }
    .card-container-appliances {
        display: none;
    }
}

@media screen and (max-width: 1050px) {
    .card-container {
       width: 35vw;
    }

    .card-container-appliances {
        display: none;
    }

    .card-container-beauty {
        display: none;
    }
}
