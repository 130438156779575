.no-reviews-container {
    display: flex;
    align-items: flex-start;
    gap: 100px;
}

.no-reviews-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

.no-reviews-header {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid #D5D9D9;
    padding-bottom: 10px;
}

.review-this-product-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.review-this-product {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    font-size: 18px;
    font-weight: 600;
}

.share-thoughts {
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.write-a-review-link {
    padding: 0px 10px;
    font-size: 13px;
    line-height: 29px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
    border: 1px solid #D5D9D9;
    text-align: center;
    color: #0F1111;
    cursor: pointer;
}

.write-a-review-link:hover {
    background-color: #F7FAFA;
    border-color: #D5D9D9;
    color: #0F1111;
    text-decoration: none;
}

.product-data-reviews-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 80px;
}

.product-reviews-container {
    width: 55vw;
}

.review-images-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.reviews-bottom-header {
    font-size: 18px;
    font-weight: 600;
    margin: 30px 0 0 0;
}

.product-reviews-top, .product-reviews-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.OpenModalButton.review-image-modal {
    height: 150px;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
}

.review-images-container {
    display: flex;
    height: auto;
    gap: 7px;
    /* margin-bottom: 10px; */
}

.review-image-image {
    height: 150px;
    width: 150px;
    object-fit: fill;
}

.see-all-images-modal {
    border: none;
    background: none;
    font-size: 15px;
    font-weight: 500;
    color: #007185;
    margin: 10px 0;
    padding: 0;
    cursor: pointer;
}

.see-all-images-modal:hover {
    color: #c45500;
}


.review-data-wrapper {
    height: fit-content;
    gap: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #D5D9D9;
}

.review-data-inside {
    height: 290px;
    border-bottom: 1px solid #D5D9D9;
}

.review-this-product-under {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    font-size: 18px;
    font-weight: 600;
}


.review-this-product-container-under {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 30px;
    /* border-bottom: 1px solid #D5D9D9; */
}
